import React from "react"
import mobileNavigationStyles from "../../styles/header/mobile-navigation.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const MobileNavigation = ({ open, menuLinks}) => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  // const today_month = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(3,5)  

  // if (today_month !== '12') {
  //   menuLinks = menuLinks.filter(link => link.name !== "Calendario dell'Avvento")}

  return (
    <nav
      className={mobileNavigationStyles.navigation}
      style={{ transform: open ? "translateX(0)" : "translateX(-100%)" }}
    >
      <Link className={mobileNavigationStyles.logo} to="/"><Img fluid={data.file.childImageSharp.fluid} alt="logo" /></Link>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name} className={mobileNavigationStyles.link}>
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default MobileNavigation