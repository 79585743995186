import React from "react"
import footerStyles from "../../styles/footer/footer.module.scss"
import { Link } from "gatsby"
import EmailIcon from "../../assets/mail.svg"
import FacebookIcon from "../../assets/facebook.svg"
import WhatsappIcon from "../../assets/whatsapp.svg"
import InstagramIcon from "../../assets/instagram.svg"

const Footer = () => {
  return (
    <>
        <div className={footerStyles.footer}>
        <div className={footerStyles.social}>
            <a
                href="mailto:info@massoterapiasavona.it"
                target="_blank"
                rel="noreferrer"
              >
               <EmailIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://www.facebook.com/massoterapiasavona.it/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://www.instagram.com/donatapistone/"
                target="_blank"
                rel="noreferrer"
              >
               <InstagramIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://wa.me/393394650857?text=Ciao%20Donata%20ti%20contatto%20per%20informazioni"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsappIcon className={footerStyles.icon} />
              </a>
            </div>
      
          <div className={footerStyles.contatti}>
              <span >
              via XX Settembre n. 16/3 - 17100 Savona
              </span>
              <span >P.IVA 01591770092</span>
              <span >
                info@massoterapiasavona.it
              </span>
              <span >tel/whatsapp 339 465 0857</span>
            </div>
         
            <div className={footerStyles.legal}>
            <Link to="/condizioni-di-vendita"><span >condizioni di vendita</span></Link>
            <Link to="/privacy-policy"><span>privacy policy</span></Link>
            <Link to="/cookie-policy"><span>cookie policy</span></Link>
          </div>
          </div>
      <p className={footerStyles.copy}>{`© ${new Date().getFullYear()} MiPrendoCuraDiTe di Donata Pistone Riflessologia Plantare - Tutti i diritti riservati`}</p>
    </>
  )
}

export default Footer 