import React from "react"
import MenuIcon from "../../assets/menu.svg"
import CloseIcon from "../../assets/close.svg"
import mobileMenuIconStyles from "../../styles/header/mobile-menu-icon.module.scss"

const MobileMenuIcon = ({ open, setOpen }) => {
  return (
    <div
      open={open}
      role="button"
      tabIndex="0"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      {open ? (
        <CloseIcon
          className={`${mobileMenuIconStyles.iconClose} ${mobileMenuIconStyles.bg}`}
        />
      ) : (
        <MenuIcon className={mobileMenuIconStyles.iconMenu} />
      )}
    </div>
  )
}

export default MobileMenuIcon
