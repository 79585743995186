import React, { useState, useRef, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import headerStyles from "../../styles/header/header.module.scss"
import CartIcon from "../../assets/cart.svg"
import MobileMenuIcon from "./mobileMenuIcon"
import MobileNavigation from "./mobileNavigation"
import Img from "gatsby-image"
import  useOnClickOutside  from "../hooks/useOnClickOutside"
import { StoreContext } from "../../context/storeContext"

const Header = ({ menuLinks, location }) => {
    const { cartCount } = useContext(StoreContext)
    const [open, setOpen] = useState(false)
    const node = useRef()
    useOnClickOutside(node, () => setOpen(false))

    // const today_month = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(3,5)     

    // if (today_month !== '12') {
    // menuLinks = menuLinks.filter(link => link.name !== "Calendario dell'Avvento")}

    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
    <nav>
      <div className={headerStyles.nav}></div> 
      <div ref={node}>
        <MobileMenuIcon
          open={open}
          setOpen={setOpen}
          className={`${headerStyles.icon} ${headerStyles.menuIcon}`}
        />
        <MobileNavigation
          open={open}
          menuLinks={menuLinks}
        />
      </div>
      <ul className={headerStyles.navigation}>
          <li key={data.file.childImageSharp.fluid.name} className={headerStyles.logo}>
            <Link to="/"><Img fluid={data.file.childImageSharp.fluid} alt="logo" /></Link>
          </li>
        {menuLinks.map(link => (
          <li key={link.name} className={headerStyles.linkLg}>
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </ul>
      <Link to="/carrello">
        <CartIcon className={headerStyles.cartIcon} />
        <span className={headerStyles.cartCount}>{cartCount}</span>
      </Link>
    </nav>
  </>
)
}

export default Header